<template src="./MainLayout.html"></template>
<style scoped lang="scss" src="./MainLayout.scss"></style>

<script>
const Sidebar = () => import('./element/sidebar/Sidebar')
const Header = () => import('./element/header/Header')

export default {
  name: 'MainLayout',
  components: {
    Header,
    Sidebar
  },
  data() {
    return {
      isShowSideBar: true,
    }
  },
  computed: {
    classObj() {
      return this.isShowSideBar ? 'showSidebar' : 'hideSidebar'
    }
  },
  methods: {
    handleLogout() {
      console.log('handleLogout')
    },
    handleShowSideBar() {
      this.isShowSideBar = !this.isShowSideBar
    },
  }
}
</script>
