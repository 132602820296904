<template src="./App.html"></template>
<style scoped lang="scss" src="./App.scss"></style>

<script>
import { eventBusService } from "@/services";
const DeleteModal = () => import('@/components/modal/delete-confirm-modal/DeleteConfirmModal')

export default {
  name: 'App',
  components: { DeleteModal },
  data: function () {
    return {
      isShowDeleteDialog: false,
      isDeletingLoading: false,
      deleteDialogTitle: '',
      deleteDialogMessage: '',
      deleteDialogSubMessage: '',
    }
  },
  created() {
    eventBusService.$on(eventBusService.SHOW_DELETE_MODAL, (data) => this.handleShowDeleteDialog(data))
    eventBusService.$on(eventBusService.HIDE_DELETE_MODAL, (data) => this.handleHideDeleteDialog(data))
  },
  methods: {
    handleHideDeleteDialog() {
      this.resetDeleteDialog()
    },

    handleShowDeleteDialog(data) {
      this.isShowDeleteDialog = true
      this.deleteDialogTitle = data.title || 'Delete'
      this.deleteDialogMessage = data.message || 'Do you want to delete?'
      this.deleteDialogSubMessage = data.subMessage || ''
    },

    onDeleteConfirmSubmit() {
      this.isDeletingLoading = true
      eventBusService.$emit(eventBusService.SHOW_DELETE_MODAL_SUBMIT)
    },

    onDeleteConfirmCancel() {
      this.resetDeleteDialog()
    },

    onDeleteConfirmClose() {
      this.resetDeleteDialog()
    },

    resetDeleteDialog() {
      this.isShowDeleteDialog = false
      this.isDeletingLoading = false
      this.deleteDialogTitle = ''
      this.deleteDialogMessage = ''
      this.deleteDialogSubMessage = ''
    }
  }
}
</script>
