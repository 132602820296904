import service from './base.service'

export default class MasterService {
  RESOURCE = 'masters'

  /**
   * Get by id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getById(masterId) {
    return service.post(`${this.RESOURCE}/${masterId}`)
  }

  /**
   * Get list items by type
   * @param queryParams
   * @returns {Promise<AxiosResponse<any>>}
   */
  getItemsList(queryParams) {
    return service.get(`${this.RESOURCE}/items`, { params: queryParams })
  }
}
