const COOKIES = {
  accessToken: 'access_token',
  role: 'role'
}
const TypeMasterData = {
  category: 0,
  body: 1,
  brand: 2,
  factory: 3,
  importer: 4,
  problemContent: 5,
  classify: 6,
  size: 7,
  color: 8,
}

const ErrorCode = {
  AuthError: 609,
  LoginError: 461,
  RegisterError: 611,
  ForgotPassword: 463,
  ChangePassword: 613,
  ResetPassword: 462
}

const MasterDataTypes = [{
    id: TypeMasterData.category,
    name: 'カテゴリ'
  },
  {
    id: TypeMasterData.body,
    name: 'ボディ'
  },
  {
    id: TypeMasterData.brand,
    name: '会社名'
  },
  {
    id: TypeMasterData.factory,
    name: '発注工場'
  },
  {
    id: TypeMasterData.importer,
    name: '担当者'
  },
  {
    id: TypeMasterData.problemContent,
    name: '案件内容'
  },
  {
    id: TypeMasterData.classify,
    name: '区分'
  },
  {
    id: TypeMasterData.size,
    name: 'サイズ'
  },
  {
    id: TypeMasterData.color,
    name: 'カラー'
  },
]

const OrderType = {
  Sample: 0,
  Mass: 1
}

const FilterData = {
  Debounce: 200,
  Page: 1,
  Limit: 15
}

const Permission = {
  Admin: 0,
  User: 1
}

const FilterLimit = {
  firstLimit: '30',
  secondLimit: '100',
  thirdLimit: '300',
  fulLimit: 'すべて'
}

export {
  FilterData,
  TypeMasterData,
  MasterDataTypes,
  OrderType,
  COOKIES,
  ErrorCode,
  Permission,
  FilterLimit
}
