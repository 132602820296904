import Vue from 'vue'
import VueRouter from 'vue-router'

// Layout
import MainLayout from "../views/layout/main/MainLayout";
import AuthLayout from "../views/layout/auth/AuthLayout";
import ErrorLayout from "../views/layout/error/ErrorLayout";
import {COOKIES, Permission } from "@/constants";

import VueCookies from "vue-cookies";

// Pages
const MasterManagementSearchPage = () => import('../views/pages/main/master-management/SearchPage/SearchMasterManagementPage');
const MasterManagementCreatePage = () => import('../views/pages/main/master-management/CreatePage/CreateMasterManagementPage');
const MasterManagementEditPage = () => import('../views/pages/main/master-management/EditPage/EditMasterManagementPage');

const SampleManagementSearchPage = () => import('../views/pages/main/sample-management/SearchPage/SearchSampleManagementPage');
const SampleManagementCreatePage = () => import('../views/pages/main/sample-management/CreatePage/CreateSampleManagementPage');
const SampleManagementEditPage = () => import('../views/pages/main/sample-management/EditPage/EditSampleManagementPage');
const MassManagementSearchPage = () => import('../views/pages/main/mass-management/SearchPage/SearchMassManagementPage');
const MassManagementCreatePage = () => import('../views/pages/main/mass-management/CreatePage/CreateMassManagementPage');
const MassManagementEditPage = () => import('../views/pages/main/mass-management/EditPage/EditMassManagementPage');
const CompanyCreatePage = () => import('../views/pages/main/company-management/CreatePage/CreateCompanyPage');

const Login = () => import('../views/pages/auth/login/Login');
const ForgotPassword = () => import('../views/pages/auth/forgot-password/ForgotPassword');
const ForgotPasswordSuccess = () => import('../views/pages/auth/forgot-password-success/ForgotPasswordSuccess');
const ResetPassword = () => import('../views/pages/auth/reset-password/ResetPassword');
const ChangePassword = () => import('../views/pages/auth/change-password/ChangePassword');

const ErrorNotFound = () => import('../views/pages/error/not-found/ErrorNotFound');
const ErrorInternal = () => import('../views/pages/error/internal/ErrorInternal');

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'routes.home',
    component: MainLayout,
    redirect: {name: 'routes.sampleManagement'},
    meta: {requiresAuth: true},
    children: [
      {
        path: 'sample-management',
        name: 'routes.sampleManagement',
        meta: { requiresAuth: true, permission: [Permission.Admin, Permission.User]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: SampleManagementSearchPage
      },
      {
        path: 'sample-management/create',
        name: 'routes.sampleManagement.create',
        meta: {requiresAuth: true, permission: [Permission.Admin]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: SampleManagementCreatePage
      },
      {
        path: 'sample-management/:id/edit',
        name: 'routes.sampleManagement.edit',
        meta: {requiresAuth: true, permission: [Permission.Admin]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: SampleManagementEditPage
      },
      {
        path: 'mass-management',
        name: 'routes.massManagement',
        meta: {requiresAuth: true, permission: [Permission.Admin, Permission.User]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: MassManagementSearchPage
      },
      {
        path: 'mass-management/create',
        name: 'routes.massManagement.create',
        meta: {requiresAuth: true, permission: [Permission.Admin]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: MassManagementCreatePage
      },
      {
        path: 'mass-management/:id/edit',
        name: 'routes.massManagement.edit',
        meta: {requiresAuth: true, permission: [Permission.Admin]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: MassManagementEditPage
      },
      {
        path: 'master-management',
        name: 'routes.masterManagement',
        meta: {requiresAuth: true, permission: [Permission.Admin]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: MasterManagementSearchPage
      },
      {
        path: 'master-management/create',
        name: 'routes.masterManagement.create',
        meta: {requiresAuth: true, permission: [Permission.Admin]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: MasterManagementCreatePage
      },
      {
        path: 'master-management/:id/edit',
        name: 'routes.masterManagement.edit',
        meta: {requiresAuth: true, permission: [Permission.Admin]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: MasterManagementEditPage
      },
      {
        path: 'company-management/create',
        name: 'routes.companyManagement.create',
        meta: {requiresAuth: true, permission: [Permission.Admin]},
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        component: CompanyCreatePage
      },
    ]
  },
  {
    path: '/auth',
    name: 'routes.auth',
    component: AuthLayout,
    redirect: {name: 'routes.auth.login'},
    children: [
      {
        path: 'login',
        name: 'routes.auth.login',
        component: Login,
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        meta: {requiresAuth: false},
      },
      {
        path: 'forgot-password',
        name: 'routes.auth.forgotPassword',
        component: ForgotPassword,
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        meta: {requiresAuth: false},
      },
      {
        path: 'forgot-password-success',
        name: 'routes.auth.forgot-password-success',
        component: ForgotPasswordSuccess,
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        meta: {requiresAuth: false},
      },
      {
        path: 'reset-password',
        name: 'routes.auth.reset-password',
        component: ResetPassword,
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        meta: {requiresAuth: false},
      },
      {
        path: 'change-password',
        name: 'routes.auth.change-password',
        component: ChangePassword,
        beforeEnter: (to, from, next) => checkPermission(to, from, next),
        meta: {requiresAuth: false},
      }
    ]
  },
  {
    path: '/errors',
    name: 'routes.errors',
    component: ErrorLayout,
    redirect: {name: 'routes.errors.404'},
    children: [
      {
        path: '404',
        name: 'routes.errors.404',
        component: ErrorNotFound,
        meta: {requiresAuth: false},
      },
      {
        path: '500',
        name: 'routes.errors.500',
        component: ErrorInternal,
        meta: {requiresAuth: false},
      }
    ]
  },
  {
    path: '*',
    name: 'routes.errors',
    component: ErrorLayout,
    redirect: { name: 'routes.notFound'},
    children: [
      {
        path: '404',
        name: 'routes.notFound',
        component: ErrorNotFound,
        meta: {requiresAuth: false},
      },
    ]
  }
]

const checkPermission = (to, from, next) => {
  const userRole = Number(VueCookies.get(COOKIES.role))
  const permission = to.meta.permission || []

  if (!permission || !permission.length) return next()
  if (!permission.includes(userRole)) return next({ name: 'routes.errors.404' })

  return next()
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const accessToken = VueCookies.get(COOKIES.accessToken)

  if (!to.meta.requiresAuth) {
    if (to.name !== 'routes.auth.login') return next()
    if (to.name === 'routes.auth.login' && accessToken) return next({ name: 'routes.home' })
  }

  if (to.meta.requiresAuth && accessToken) return next()
  if (to.name === 'routes.auth.login') return next();

  return next({name: 'routes.auth.login'})
})

export default router
