import service from './base.service'

export default class MassManagementService {
  RESOURCE = 'orders';
  COMPANY_ORDER_RESOURCE = 'my-orders'

  /**
   * Get by id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getById(orderId) {
    return service.get(`${this.RESOURCE}/${orderId}`)
  }

  /**
   * Create new order
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  create(data) {
    return service.post(`${this.RESOURCE}`, data);
  }

  /**
   * Update a order by Id
   * @param orderId
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  update(orderId, payload) {
    return service.put(`${this.RESOURCE}/${orderId}`, payload);
  }

  /**
   * Search list items
   * @param queryParam
   * @returns {Promise<AxiosResponse<any>>}
   */
  search(queryParam) {
    return service.get(`${this.RESOURCE}`, { params: queryParam });
  }

  /**
   * Search order of a company
   * @param queryParam
   * @returns {Promise<AxiosResponse<any>>}
   */
  searchCompanyOrder(queryParam) {
    return service.get(`${this.COMPANY_ORDER_RESOURCE}`, { params: queryParam });
  }

  /**
   * Delete order by ID
   * @param orderId
   * @returns {Promise<AxiosResponse<any>>}
   */
  delete(orderId) {
    return service.delete(`${this.RESOURCE}/${orderId}`);
  }

  /**
   * Export CSV
   * @param queryParam
   */
  export(queryParam) {
    return service.get(`${this.RESOURCE}/export`, { params: queryParam });
  }
}
