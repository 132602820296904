import Vue from 'vue'
import App from './app/App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ja'

Vue.config.productionTip = false

Vue.use(ElementUI, {
    size: 'small',
    zIndex: 1,
    locale
});
// Cookie
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

//Moment
import moment from 'moment'
Vue.prototype.moment = moment
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
