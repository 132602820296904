import service from './base.service'

export default class UserService {
  RESOURCE = 'users'

  /**
   * Get by id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getBrandById(brandId) {
    return service.get(`${this.RESOURCE}/find-with-brand/${brandId}`)
  }

  /**
   * Get list items by type
   * @returns {Promise<AxiosResponse<any>>}
   * @param payload
   */
  create(payload) {
    return service.post(`${this.RESOURCE}`, payload);
  }
}
