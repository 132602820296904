import service from './base.service'

export default class MasterDataService {
  RESOURCE = 'masters'

  /**
   * Get by id
   * @returns {Promise<AxiosResponse<any>>}
   */
  search(params) {
    return service.get(`${this.RESOURCE}`, {params});
  }

  delete(id) {
    return service.delete(`${this.RESOURCE}/${id}`);
  }

  create(payload) {
    return service.post(`${this.RESOURCE}`, payload);
  }

  getById(id) {
    return service.get(`${this.RESOURCE}/${id}`);
  }

  update(orderId, payload) {
    return service.put(`${this.RESOURCE}/${orderId}`, payload);
  }
}
